//USE THIS APPROACH
.modal {
  display: flex!important;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;

  .modal-dialog {
    margin: 0 !important;

    @media (max-width: 576px) {
      margin: 0;
      padding: 0;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      min-height: 100%;
      max-height: 100%;

      .modal-content {
        width: 100%;
        height: 100%;
        border: unset;
        position: unset;
        border-radius: unset;
      }
    }

    @media (max-width: 768px) {
      border-radius: 0px;
      display: flex;
      margin:0;
      padding: 0;
      width:100%;
      max-width: 100%;
      height: 100%;
      min-height: 100%;

      .modal-content {
        width: 100%;
        height: 100%;
        border: unset;
        position: unset;
        border-radius: unset;
      }
    }

    @media (min-width: 769px) {
      &.modal-default {
        width: 500px;
        max-width: 500px;
      }

      &.modal-small {
        width: 400px;
        max-width: 400px;
      }

      &.modal-medium {
        width: 550px;
        max-width: 550px;
      }

      &.modal-double-width {
        width: 816px;
        max-width: 816px;
      }

      &.modal-650 {
        width: 650px;
        max-width: 650px;
      }

      &.activity-add-modal {
        width: 650px;
        max-width: 650px;
      }

      &.gantt-light-box-modal {
        width: 430px;
        max-width: 430px;
        height: unset;
        max-height: unset;
      }

      &.task-details-modal {
        width: 650px;
        max-width: 750px;
        height: unset;
        max-height: unset;
      }

      &.modal-select-zone-event {
        width: 600px;
        max-width: 600px;
      }

      &.modal-80 {
        width: 80%;
        max-width: 80%;
        height: unset;
        max-height: 80%;
      }

      &.modal-sustainability-dashboard-widgets {
        width: 816px;
        max-width: 816px;
        height: unset;
        max-height: 100%;
        transition: none !important;
      }

      &.planning-dashboard-widgets-layout-config {
        width: 816px;
        max-width: 816px;
        height: unset;
        max-height: 100%;
        transition: none !important;
      }

      &.incident-dashboard-widgets-layout-config {
        width: 816px;
        max-width: 816px;
        height: unset;
        max-height: 100%;
        transition: none !important;
      }

      &.dashboard-widget-modal-view {
        width: 80%;
        max-width: 80%;
        height: 80%;
        max-height: 80%;

        .modal-content {
          width: 100%;
          height: 100%;
        }
      }

      &.modal-indicator-target-add {
        width: 800px;
        max-width: 800px;
      }

      &.modal-full-screen {
        display: block;
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        background-color: var(--white);
        border-radius: 0;
        margin: 0;
        overflow: unset;
        transition: none !important;

        .modal-content {
          width: 100%;
          height: 100%;
          border: unset;
          position: unset;
          border-radius: unset;
        }
      }

      &.modal-suggested-item-add {
        .modal-content {
          width: 100%;
          height: 100%;
          border: unset;
          position: unset;
          border-radius: unset;
        }
      }

    }
  }
}

.modal-image-preview {
  overflow: initial;
  margin: unset;
  height: unset;
  min-height: unset;
  width: 816px;
  max-width: 816px;
  box-shadow: none !important;
  background-color:rgba(0, 0, 0, 0) !important;

  .modal-content {
    border: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
  }
}


//TODO: Get rid of all this but first replace wherever needed with the new approach!
.modal-large-add {
  overflow: initial;
  margin: unset;
  height: unset;
  min-height: unset;
  width: 816px;
  max-width: 816px;
  box-shadow: 1px 4px 16px 0 rgba(0, 0, 0, 0.08), 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  border-radius: 4px;

  @media (max-width: 816px) {
    width: 100%;
    margin-top: 0;
  }
  @media (max-width: 767.98px) {
    height: 100%;
    border-radius: 0;
  }

  .add-modal-container {
    background-color: var(--white);
    padding: 24px;
    @media (max-width: 768px) {
      padding: env(safe-area-inset-top, 24px) 24px env(safe-area-inset-bottom, 24px);
    }
    @media (max-width: 768px) {
      padding: env(safe-area-inset-top, 24px) 24px env(safe-area-inset-bottom, 24px);
    }
  }

  .modal-header-container {
    font-size: 16px;
    color: var(--wt-black);
    padding-bottom: 12px;
    border-bottom: 1px solid var(--wt-grey-light);
    .close-icon {
      cursor: pointer;
    }
  }

  .modal-body-container {
    padding-top: 16px;

    .bottom-element {
      margin-left: 12px;
      line-height: 21px;
    }

    &.custom-gutters {
      margin-right: -32px;
      margin-left: -32px;

      > [class*='col-'] {
        padding-right: 32px;
        padding-left: 32px;
      }
    }

    .buttons-row {
      .action-buttons {
        display: flex;

        >:first-child {
          margin-right: 8px;
        }
        @media (max-width: 992px) {
          flex: 1;
          flex-wrap: wrap;
          .button {
            flex: 1;
          }
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .key-shortcut {
    font-weight: 600;
  }
}

modal-container:has(> .custom-add-risk-modal-backdrop) {
  z-index: 1200 !important;
}

.modal-medium-add {
  @extend .modal-large-add;
  width: 642px;
  max-width: 642px;
}

.modal-small-add {
  overflow: initial;
  margin: unset;
  height: unset;
  min-height: unset;
  width: 400px;
  max-width: 400px;
  box-shadow: 1px 4px 16px 0 rgba(0, 0, 0, 0.08), 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  border-radius: 4px;

  @media (max-width: 816px) {
    width: 100%;
    margin-top: 0;
  }
  @media (max-width: 767.98px) {
    height: 100%;
    border-radius: 0;
  }

  .add-modal-container {
    background-color: var(--white);
    padding: 24px;
  }

  .modal-header-container {
    font-size: 16px;
    color: var(--wt-black);
    padding-bottom: 12px;
    border-bottom: 1px solid var(--wt-grey-light);
    .close-icon {
      cursor: pointer;
    }
  }

  .modal-body-container {
    padding-top: 16px;

    .bottom-element {
      margin-left: 12px;
      line-height: 21px;
    }

    &.custom-gutters {
      margin-right: -32px;
      margin-left: -32px;

      > [class*='col-'] {
        padding-right: 32px;
        padding-left: 32px;
      }
    }

    .buttons-row {
      .action-buttons {
        display: flex;

        >:first-child {
          margin-right: 8px;
        }
        @media (max-width: 992px) {
          flex: 1;
          flex-wrap: wrap;
          .button {
            flex: 1;
          }
          &:first-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .key-shortcut {
    font-weight: 600;
  }
}

.modal-notifications-center {
  position: absolute;
  left: 0;
}

.modal-suggested-item-add {
  @extend .modal-small-add;
  width: 414px;
  max-width: 414px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.custom-gutters {
  margin-right: -32px;
  margin-left: -32px;

  > [class*='col-'] {
    padding-right: 32px;
    padding-left: 32px;
  }
}
.col-row-space {
  > [class*='col-'] {
    margin-top: 8px;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0px;
    }

    &.container-with-bottom-element {
      margin-bottom: 4px;
    }
  }
}
