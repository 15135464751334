@import 'bootstrap/scss/bootstrap.scss';
@import '../node_modules/angular-calendar/css/angular-calendar.css';
@import 'ngx-toastr/toastr';
@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill-mention/dist/quill.mention.min.css';

@import './assets/styles/global.css';
@import './assets/styles/font-awesome.css';
@import './assets/styles/icon-fonts.css';
@import './assets/Libs/jsLibs/dhxGantt/codebase/dhtmlxgantt.css';
@import './assets/gantt/styles/gantt.scss';

@import './assets/layouts/index.scss';
@import './assets/styles/flags.css';
@import './assets/styles/root.scss';
@import './assets/styles/general.scss';
@import './assets/styles/modals.scss';
@import './assets/styles/animations.scss';
@import './assets/styles/quill.wt.css';
@import './assets/layouts/bootstrapCustomGrid.scss';
@import './assets/styles/utility-classes.scss';
@import './assets/styles/runsheet-items-cluster.css';
@import './assets/Libs/jsLibs/dhxGrid/grid.css';
@import './assets/styles/dhxGrid/grid-custom.scss';
