tabset:not(.custom-tabset-layout) {
  .tab-content {
    padding-bottom: 20px;
  }

  .nav-tabs {
    height: 40px;
    line-height: 42px;

    .nav-item {
      .nav-link {
        color: var(--wt-primary);
        font-weight: initial;
        font-size: 14px;
        height: 40px;
        line-height: 30px;
      }

      &.active {
        .nav-link.active {
          font-weight: bold;
        }
      }
    }
  }
}
