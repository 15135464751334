.filter-settings-row-layout {
  display: flex;
  height: 26px;

  .filter-settings-row-layout-left {
    display: flex;
    align-items: center;
    color: #939899;
    white-space: nowrap;

    .responsive-flex-wrapper {
      margin-right: 4px;

      .material-icons {
        font-size: 18px;
      }
    }

    .filter-settings-row-layout-left-description {
      line-height: 20px;
      font-size: 12px;
    }
  }

  .filter-settings-row-layout-right {
    overflow: hidden;
    margin-left: 8px;
    line-height: 20px;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
}
