.styled-table {
    border-collapse: collapse;
    margin-top: 5px;
    width: 100%;
    tr {
        td {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: var(--wt-black);
            text-align: left;
            border-top: 1px solid var(--wt-blue-pale);
            border-bottom: 1px solid var(--wt-blue-pale);
            height: 40px;
            padding-left: 12px;
        }
    }

    tr:nth-child(even) {
        background: var(--white);
    }
    tr:nth-child(odd) {        
        background: var(--off-white);
    }
}
