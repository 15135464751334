.max-width-unset {
    max-width: unset;
}

.center-transform {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
}

.grey-dark {
    color: var(--wt-grey-dark) !important;
}

.font-size-14 {
  font-size: 14px;
}

.text-light-blue {
  color: var(--wt-primary);
}
