.card-container {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border: solid 1px var(--wt-grey-light);
  background-color: var(--off-white);
  cursor: pointer;

  &:hover {
    background-color: var(--wt-blue-extra-pale);
  }

  &.small-card-container {
    border-radius: 4px;
    border-image-source: unset;
    border: solid 1px var(--wt-grey-light);
    background-color: var(--wt-blue-extra-pale);
    box-shadow: unset;
    border-image-slice: unset;
    background-image: unset;
    background-origin: unset;
    background-clip: unset;

    &.active {
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      border: solid 1px var(--wt-grey);
      background-color: var(--off-white);
      cursor: pointer;
    }
  }

  .card-button {
    min-width: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 4px;


    &.card-attachment-btn,.card-comment-btn {
      min-width: 46px;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }

    &:hover {
      background-color: var(--wt-grey-light);
    }
  }
}

.side-pane-selected-item {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 16px 16px 0;
  border-color: transparent #ffffff transparent transparent;
}


.split-pane-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .left {
    height: 100%;
    width: 300px;
    transition: 0.6s;
    display: flex;
    flex-direction: column;

    &.collapsed {
      width: 20px;

      .disabled-wrapper {
        width: 20px;
      }
    }
  }

  .right {
    width: calc(100% - 300px);
    height: 100%;
    background-color: white;
    position: relative;
    transition: 0.6s;

    .toggle-wrapper {
      position: absolute;
      left: -14px;
      top: 68px;
      width: 24px;
      height: 24px;
      background-color: white;
      border: 1px solid #ebebeb;
      border-radius: 50%;
      cursor: pointer;
      z-index: 99;

      .material-icons {
        font-size: 18px;
        color: #939899;
        transition: 0.6s;
      }
    }

    &.collapsed {
      width: calc(100% - 20px);

      .toggle-wrapper {
        .material-icons {
          transform: rotate(180deg);
        }
      }
    }
  }

  @media (max-width: 850px) {
    .left {
      display: none;
    }

    .right {
      width: 100% !important;

      .toggle-wrapper {
        display: none;
      }
    }
  }
}

.bordered-text p {
  margin-bottom: 0;
}

.dropdown-options-container {
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 1px 4px 16px 0 rgba(0, 0, 0, 0.08), 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
  background-color: var(--white);
  overflow: hidden;

  .dropdown-option {
    padding: 5px 12px;
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: var(--wt-black);

    &:first-child {
      padding-top: 12px;
    }
    &:last-child {
      padding-bottom: 12px;
    }

    &.selected {
      color: var(--wt-primary);
    }

    &:hover {
      background-color: var(--wt-blue-extra-pale);
    }

    .dropdown-check {
      font-size: 12;
      padding-left: 8px;
      color: var(--wt-primary);
    }
  }
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: var(--wt-grey-dark) var(--wt-blue-pale);

  &::-webkit-scrollbar {
    width: 11px;
    height: 9px;
  }

  &::-webkit-scrollbar-track {
    background: var(--wt-blue-pale);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--wt-grey-dark);
    border-radius: 6px;
    border: 3px solid var(--wt-blue-pale);
  }
}

.report-table-row {
  &:nth-child(odd) {
    background-color: var(--wt-blue-extra-pale);
  }

  .title-cell {
    font-size: 14px;
    color: var(--wt-black);
    padding: 5px 15px;
    vertical-align: middle;

    &.title-active-link {
      &:hover {
        color: var(--wt-primary);
        cursor: pointer;
      }
    }
  }
}
.responsive-table-header {
  height: 40px;
  .single-column-header-wrapper {
    white-space: nowrap;
    margin: 0 15px;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: var(--wt-black);

    .column-sort-icon {
      color: var(--wt-primary);
      font-size: 21px;
      margin-left: 4px;
    }
  }
}
.responsive-table {
  .poly-shape {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    background-color: #333;
    -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      width: 18px;
      height: 18px;
      background-color: #dfdfdf;
      -webkit-clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
      clip-path: polygon(60% 35%, 96% 23%, 96% 69%, 23% 93%, 3% 9%);
    }
  }
}

.title {
  margin-top: 5px;
  margin-bottom: 5px;

  .comment-box {
    margin-left: 3px;
    font-weight: bold;
  }

  .history-row {
    display: flex;
  }

  .top {
    display: flex;

    .history-action {
      display: flex;

      .name {
        margin-right: 3px;
      }
    }

    .time{
      margin-right: 10px;
      font-variant-numeric: tabular-nums
    }

    .avatar{
      margin-right: 10px;
    }
  }
}
.highlight-error-msg {
  color: var(--wt-black);
  font-weight: bold;
  font-size: 14px;
  background-color: rgba(197,70,68,0.2);
  padding: 6px;
}

.alert-mobile-status {
  --backdrop-opacity: 0.7;
  ion-backdrop {
    background-color: var(--ion-backdrop-color, #000)!important;
  }
}

// bootstrap removed classes

.order-md-6 {
  order: 6 !important;
}

.order-md-7 {
  order: 7 !important;
}

.order-md-8 {
  order: 8 !important;
}

.order-md-9 {
  order: 9 !important;
}

.order-md-10 {
  order: 10 !important;
}

.order-md-11 {
  order: 11 !important;
}

.order-md-12 {
  order: 12 !important;
}


.google-maps-wrapper {
  .infowindow-content {
    width: auto;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      padding-bottom: 3px;
      padding-top: 0px;
    }

    h1 {
      display: inline-block;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #424647;
      width: 222px;
      height: 16px;
      margin: 0 10px 0 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    hr {
      border-top: 1px solid #e0e0e0;
      height: 1px;
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .body {
      padding-left: 3px;
      padding-top: 6px;

      #category {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      #department {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
      }

      i {
        color: #c0bbbb;
        font-size: 14px;
      }

      .info {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #424647;
        margin-left: 10px;
      }
    }

    .cluster-wrapper {
      padding-left: 3px;
      padding-top: 6px;

      .cluster-object {
        display: flex;
        align-items: center;
        padding: 3px;
      }

      .details-wrapper {
        margin-top: 0px !important;
      }

      .no-width-fix {
        width: unset !important;
      }
    }

    .details-wrapper {
      width: 100%;
      margin-top: 10px;

      .what3word-container {
        float: left;
        text-align: left;
        font-size: 12px;
        height: 24px;
        line-height: 24px;

        .what3word-prefix {
          color: #c54644;
          font-weight: bold;
        }

        .what3word-address {
          color: #424647;
        }
      }

      a {
        float: right;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: var(--wt-primary);
        position: relative;
        text-decoration: none;
        line-height: 24px;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          transition: transform 0.5s ease;
        }

        &:before {
          left: 0;
          bottom: 0;
          background: var(--wt-primary);
          width: 100%;
          height: 1px;
          transform: scaleX(0);
        }
        &:hover:before {
          transform: scaleX(1);
        }
      }
    }
  }

  .incident-severity-badge, .trendrag-badge {
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    background-color: transparent;
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}

.filled-round-box {
  width: 24px;
  height: 24px;
  border-radius: 4px;
}

.wetrack-black {
  color: var(--wt-black) !important;
}
