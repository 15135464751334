.archived-banner-layout {
  display: flex;
  height: 54px;
  background-color: #fdfae5;
  align-items: center;

  .archived-banner-icon-wrapper {
    width: 46px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .archived-banner-text {
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
