$default-grid-body-font-size: 12px;
$default-grid-header-font-size: 12px;



@mixin combo-input-scroll-area {
    flex-wrap: nowrap !important;
    flex-direction: row !important;
    user-select: none;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--wt-grey-dark) var(--wt-blue-pale);

    &::-webkit-scrollbar {
        height: 2px;
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        position: absolute;
        left: 0;
        bottom: -10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--wt-grey-dark);
        border: 0px solid transparent;
    }
}

.wetrack-grid, .dhx_drag-ghost {
    .dhx_grid-row {

      &.hovered {
        background-color: var(--wt-neutral-extra-light);

        .dhx_grid-cell {
            cursor:pointer;
            color: var(--wt-primary) !important;

            .icon-container {
                .comment {
                    color: var(--wt-primary) !important;
                }
            }

            .dependent-item {
                color: var(--wt-primary) !important;
            }
        }
      }
    }

    .dhx_grid-row:hover {
        background-color: var(--wt-neutral-extra-light);

        .dhx_grid-cell {
            cursor:pointer;
            color: var(--wt-primary) !important;

            .icon-container {
                .comment {
                    color: var(--wt-primary) !important;
                }
            }

            .dependent-item {
                color: var(--wt-primary) !important;
            }
        }
    }

    // .dhx_header-wrapper {
    //     height: 70px !important;

    //     .dhx_grid-header {
    //         height: 70px !important;
    //     }
    // }

    .dhx_header-row {

        .dhx_grid-header-cell {
            font-family: 'Plus Jakarta Sans';
            color: var(--wt-black);
            background-color: #fff;
            font-weight: bold;
            font-size: $default-grid-header-font-size;

            .dhx_combobox-input-box {
                padding-right: 25px;

                .dhx_combobox-input-list-wrapper {
                    ul.dhx_combobox-input-list {
                        @include combo-input-scroll-area;
                    }
                }
            }

            .material-icons {
                font-size: 18px;

                &.link {
                    font-size: 24px !important;
                }

                &.watch-later {
                    font-size: 16px !important;
                }
            }

            &:last-child {
                border-right: 0px;
            }

            .dhx_combobox-input-box {
              border-radius: 4px;

              &:focus-within {
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
              }
            }

            .dhx_grid-filter {
              border-radius: 4px;
            }
        }

        // &:first-child {
        //     height: 30px !important;

        //     .dhx_grid-header-cell {
        //         height: 30px !important;
        //     }
        // }
    }

    .dhx_grid-content {
        border: 0px;
        border-top: 1px solid #dfdfdf;
        width: 100% !important;

        // .dhx_grid-body {
        //     width: 100% !important;
        // }
    }

    .dhx_grid-cell {
        font-family: 'Plus Jakarta Sans';
        font-size: $default-grid-body-font-size;
        color: var(--wt-black);
        border-left: 0px;

        &[data-dhx-col-id="options"] {
          padding: 0px !important;
        }

        &:last-child {
            border-right: 0px;
        }

        .bold {
            font-weight: bold;
        }

        .ellipsis-text {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .account-text {
          margin-left: 5px;
        }

        .account-logo-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          max-width: 20px;
          max-height: 20px;
          min-width: 20px;
          min-height: 20px;

          + img {
            width: 100%;
            height: 100%;
          }
        }

        .icon-container {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            .save {
                font-size: 18px;
                color: var(--wt-primary) !important;
            }

            &:hover {
                cursor: pointer;
                border-radius: 50%;
                background-color: var(--wt-grey-light);
            }
        }

        .icon-text-container {
          display: flex;
          align-items: center;
          gap: 1px;

          .material-icons {
            color: var(--wt-grey-dark);
            font-size: 16px;
          }
        }

        .priority-starts-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        .dependencies-count-container {
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--off-white);
            border: solid 1px var(--wt-grey-light);
            color: var(--wt-primary);
            border-radius: 50%;
            font-size: 10px;

            &.inactive {
                color: var(--wt-grey-light);
                cursor: not-allowed !important;
            }

            &:hover {
                background-color: var(--wt-blue-extra-pale);
            }
        }

        .time-container {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            font-family: Roboto,Arial,Tahoma,Verdana,sans-serif;
            font-size: 12px;

            &.start {
                font-weight: bold;
            }

            .value {
                position: absolute;
                top: 5px;
                right: -10px;
                font-size: 10px;
            }
        }

        .rag {
          width: 20px;
          height: 20px;
          border-radius: 2px;
          margin-right: 2px;
        }

        .severity {
          width: 20px;
          height: 20px;
          border-radius: 2px;
          margin-right: 2px;
        }

        .risk-rag-container {
            display: flex;
            gap: 5px;
        }

        i.material-icons {
            &.comment {
                font-size: 18px;
                color: var(--wt-grey-dark);
            }

            &.milestone {
              font-size: 18px;

              &.gold {
                color: var(--wt-gold);
              }

              &.silver {
                color: var(--wt-silver);
              }

              &.bronze {
                color: var(--wt-bronze);
              }
            }

            &.dependent-item {
                color: var(--wt-grey-dark);
                font-size: 14px;
                margin-right: 4px;
            }
        }

        i.material-icons-outlined {
            &.priority_star {
                font-size: 20px;
                color: var(--wt-grey-light);
            }

            &.checked {
                color: var(--wt-black);
            }

            &.hovered {
                color: var(--wt-grey-dark);
            }
        }

        &.dhx_grid-cell__editable {
            .dhx_combobox-input-box {
                .dhx_combobox-input-list-wrapper {
                    .dhx_combobox-input-list {
                        @include combo-input-scroll-area;
                    }
                }
            }


            .dhx_combobox-tag__value {
                font-size: 10px;
            }
        }

        &.invalid-cell-value {
          border: 1px solid red;
          border-top: 2px solid red;
          background-color: #fff6f6;
        }
    }

    .dhx_grid__contains_cols_right--fixed .dhx_grid_data .dhx_grid-cell:last-child {
      border-right: unset !important;
    }


    .dhx_grid-header-cell--align_left {
        .dhx_grid-header-cell-text {
            padding-right: 2px;
        }
    }

    .dhx_grid-custom-content-cell {
        padding: 5px 5px;

        .dhx_grid-filter__label {
            .dhx_input {
                font-family: 'Plus Jakarta Sans';
                font-size: 13px;
                //box-shadow: unset;
                //border-right: 1px solid #dfdfdf;
                //border-left: 1px solid #dfdfdf;
            }
        }
    }

    .dhx_grid-header-cell:not(.dhx_cell_first) {
        border-left: 0px;
    }

    .dhx_grid-footer-cell:not(.dhx_cell_first) {
        border-left: 0px;
    }

    .dhx-grid-checkbox {
        cursor: pointer;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;

        > .check-container {
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
        }

        &.checkbox-disabled > .check-container {
            .checkmark {
                background-color: #e0e0e0;
                border: 2px solid #e0e0e0;
            }
        }

        .check-container {
            display: block;
            position: relative;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        .check-container input {
            display: none;
            position: absolute;
            opacity: 0;
        }

        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
            border: 1px solid #939899;
            border-radius: 5px;
        }

        .check-container input:checked ~ .checkmark {
            background-color: var(--wt-primary);
            border: 2px solid var(--wt-primary);
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        .check-container input:checked ~ .checkmark:after {
            display: block;
        }

        .check-container .checkmark:after {
            left: 6px;
            top: 0px;
            width: 5px;
            height: 14px;
            border: 1px solid #fff;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            box-sizing: border-box;
        }

        .custom-radio-label {
            width: 16px;
            height: 16px;
            border: 2px solid #939899;
            border-radius: 3px;
            background-color: white;
            box-sizing: content-box;

            &:hover {
              border-radius: 3px;
              background-color: var(--wt-neutral-extra-light);
            }
        }
    }

    .dhx_cell-editor__combobox .dhx_combobox-input-box {
        padding: 0 25px 0 8px;
        box-shadow: inset 0 0 0 1px var(--wt-primary);
    }

    .dhx_cell-editor{
        box-shadow:inset 0 0 0 1px var(--wt-primary) !important;
    }

    .max-priority {
        background: var(--wt-gold-light);
    }

    .dhx_grid-fixed-cols-wrap {
      //padding-top: 70px !important;
      box-shadow: -3px 0px 4px -3px rgb(0 0 0 / 18%);

      // .dhx_grid__cols_right--fixed {
      //   top: 69px !important;
      // }
    }

    .dhx_header-fixed-cols {
      &.dhx_grid__cols_right--fixed {
        box-shadow: -3px 0px 4px -3px rgb(0 0 0 / 18%);

        // .dhx_grid-header {
        //   height: 70px !important;
        // }
      }
    }
}

.dhx_drag-ghost {
    .dhx_grid-cell--drag {
        border: 0px !important;
        background-color: var(--wt-blue-pale);
    }

    .icon-container {
        display: none !important;
    }
}

.dhx_widget {
    &.dhx_layout-rows {
        li.dhx_list-item {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 13px;
        }
    }
}

.context-menu-custom-html {
    display: flex;
    padding: 8px 12px 8px 8px;
    line-height: 20px;
    justify-content: flex-start;
    align-items: center;
    color: var(--wt-black);

    &.splitter {
      border-bottom: 1px solid var(--wt-grey-light);
    }

    &.danger {
        color: red;
    }

    &:hover {
        cursor: pointer;
        background-color: #ededed;
        transition: background-color .2s ease-out;
    }

    .wrapper {
        display: flex;
        align-items: center;
        margin-left: 5px;

        .time {
            width: 18px;
            border: 0px;
            border-bottom: 2px solid lightgray;
            margin: 0 5px 0px 5px;
            text-align: center;
        }

        select {
            border: 0px;
            border-bottom: 2px solid lightgray;
            margin-left: 5px;
        }
    }

    button {
        padding: 3px 10px;
        margin-left: 20px;
        color: var(--white);
        background-color: var(--wt-primary);
        border-radius: 4px;
        box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 24%), -1px -1px 1px 0 rgb(0 0 0 / 8%);
    }

    .icon {
        opacity: 0.7;
        font-size: 20px !important;
        line-height: 20px !important;
    }

    .label {
        margin-left: 8px;
    }
}


.dependency-tooltip-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 5px;

    .dhx-grid-tooltip-message {
        display: flex;
        align-items: center;
        font-size: 13px;
        color: var(--wt-black);
        gap: 3px;

        .material-icons {
            font-size: 16px;
        }
    }

    .bottom-container {
      display: flex;
      margin-top: 1px;
      margin-left: 18px;
      font-size: 14px;
      font-weight: bold;
      gap: 5px;
    }
}

.runsheet-timedit-popup {
    animation: none !important;
}

.time-edit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    box-shadow: inset 0 0 0 1px var(--wt-primary);
    border-radius: 2px;
    padding: 0 8px;

    &.hidden {
        display: none !important;
    }
}

.dhx_tooltip {
    pointer-events: none;
    border-width: 1px;
    border-radius: 4px;
    box-shadow: var(--wt-box-shadow);
    background-color: var(--white);
    padding:6px 12px;
    transition: opacity 0.01s ease, transform 0.01s ease;
    opacity:0;
    z-index:999;
    font-family: 'Plus Jakarta Sans';

    &.header-tooltip {
        font-weight: bold !important;
    }

    .bold {
      font-weight: bold;
    }
}


.dhx_tooltip--animate {
    opacity: 1;
    transition: opacity 0.15s ease-out;
  }
  .dhx_tooltip--forced {
    opacity: 1;
  }
  .dhx_tooltip--bottom {
    margin: 8px 0 0;
  }
  .dhx_tooltip--top {
    margin: -8px 0 0;
  }
  .dhx_tooltip--left {
    margin: 0 0 -8px;
  }
  .dhx_tooltip--right {
    margin: 0 0 0 8px;
  }
  .dhx_tooltip--light {
    background-color: #fff;
  }
  .dhx_tooltip--light .dhx_tooltip__text {
    color: rgba(0, 0, 0, 0.7);
  }
  .dhx_widget--fullscreen .dhx_tooltip {
    z-index: 2147483647;
  }
  .dhx_tooltip__text {
    font-size: 12px;
    color: var(--wt-black);
    line-height: 20px;
  }

  #dependent_items_column_wrapper {
      display: flex;
  }

.dhx_widget {
  &.dhx_popup {
    .dhx_popup-content {
      .dhx_list {
        .dhx_list-item {
          background-color: unset;
          border-bottom: unset;

          &:not(.dhx_list-item--selected) {
            .header-filter-item-container {
              i.material-icons.selected {
                display: none;
              }
            }
          }

          &.dhx_list-item--selected {
            background-color: unset;
            color: var(--wt-primary);
            box-shadow: unset;
          }

          &:hover {
            background-color: var(--wt-blue-extra-pale);
            color: var(--wt-primary);
            cursor: pointer;
          }

          .header-filter-item-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            i.material-icons {
              &.selected {
                color: var(--wt-primary);
              }

              &.remove {
                color: var(--wt-red);
                display: none;
              }
            }
          }
        }

        &[aria-multiselectable="true"] {
          .dhx_list-item {
            &:hover {
              &.dhx_list-item--selected {
                .header-filter-item-container {
                  i.material-icons {
                    &.remove {
                      display: block;
                    }
                    &.selected {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.dhx_grid_tooltip {
    .account-tooltip-logo {
      display: flex;
      flex-direction: column;
      align-items: center;

      .account-logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 100px;
        max-width: 100px;
        max-height: 100px;
        min-width: 100px;
        min-height: 100px;

        + img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

