@use 'sass:color';

$gantt-font-size: 11px;
$header-scales-font-size: 11px;

$gantt-task-row-height: 30px;
$gantt-default-task-height: 18px;
$gantt-milestone-task-height: 20px;
$gantt-sub-task-height: 14px;
$gantt-default-task-left-side-adjust-y-offset: calc(-1 * ($gantt-task-row-height - $gantt-default-task-height)/2);
$gantt-milestone-task-left-side-adjust-y-offset: calc(-1 * ($gantt-task-row-height - $gantt-milestone-task-height)/2);
$gantt-sub-task-left-side-adjust-y-offset: calc(-1 * ($gantt-task-row-height - $gantt-sub-task-height)/2);

$rag-on-track-color: #329F5B;
$rag-sleeping-color: #FD9C38;
$rag-not-started-color: #939499;
$rag-slipped-color: #F41B1B;
$rag-complete-color: #0574DB;

$row-selection-color: #fff3a1;

@mixin header-scales-bg {
  font-size: $header-scales-font-size;
  color: var(--wt-grey-dark);
  background-color: #fafafa;
}

@mixin gantt-file-default-margins {
  margin-left: 6px;
  margin-right: 6px;
}

@mixin gantt-file-icon($icon) {
  background-image: url($icon);
  margin-left: 6px;
  margin-right: 6px;
}

@mixin weekend {
  background-color: var(--wt-blue-extra-pale);
  color: var(--wt-blue-dark) !important;
}

@mixin darken-project-border-color($task-color, $amount-procent) {
  border-top: 1px solid color.scale($task-color, $lightness: -$amount-procent);
  border-left: 1px solid color.scale($task-color, $lightness: -$amount-procent);
  border-right: 1px solid color.scale($task-color, $lightness: -$amount-procent);
  border-bottom: 1px solid transparent;
}

@mixin darken-task-border-color($color, $amount-procent) {
  border: 1px solid color.scale($color, $lightness: -$amount-procent);
}

@mixin gantt-project-line($color, $border-height, $task-height, $bgOpacity: 0.17) {
  background-color: rgba($color, $bgOpacity) !important;
  height: $task-height !important;
  @include darken-project-border-color($color, 20%);

  .gantt_task_content  {
    color: white;
    font-size: 10px;
    height: 100%;
    overflow: hidden;
    box-shadow: inset 0 $border-height 0 0 $color;

    &::before {
      right: 0;
      border-width: 0 $border-height $border-height 0;
      border-color: transparent $color transparent transparent;
      max-width: 100%;
      content: "";
      display: block;
      width: 0;
      height: 0;
      top: 50%;
      position: absolute;
      border-style: solid;
    }

    &:after {
      left: 0;
      border-width: $border-height $border-height 0 0;
      border-color: $color transparent transparent;
      max-width: 100%;
      content: "";
      display: block;
      width: 0;
      height: 0;
      top: 50%;
      position: absolute;
      border-style: solid;
    }
  }

  .gantt_task_progress {
    opacity: 1;
    border-radius: 0 0 2px 2px;
    background: rgba($color, .5);
  }
}

@mixin gantt-subtask-line() {
  height: 14px !important;
  transform: translateY(2px);

  .gantt_link_control {
    display: none;

    &.task_left, &.task_right {
      top: -3px;
    }
  }
}

@mixin empty-cell-stripes($odd-color) {
  background: repeating-linear-gradient(
    -55deg,
    white,
    white, 4px,
    $odd-color 4px,
    $odd-color 8px
  );
}

@mixin empty-cell-container($odd-color) {
  .empty-cell-container {
    .stripes-bg {
      @include empty-cell-stripes($odd-color);
    }
  }
}

.gantt_container {
  font-size: $gantt-font-size;
  border: 0px;
  position: relative;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;

  &.gantt_layout_cell_border_top {
    border-top: 1px solid #cecece;
  }

  &.gantt_layout_cell_border_bottom {
    border-bottom: 0;
  }

  &.gantt_layout_cell_border_left {
    border-left: 0;
  }

  &.gantt_layout_cell_border_right {
    border-right: 0;
  }
}

.gantt_grid {
  -webkit-transition: margin .1s;
  -moz-transition: margin .1s;
  -o-transition: margin .1s;
  transition: margin .1s;

  .gantt_grid_scale {
    .gantt_grid_head_cell {
      &.wet-grid-header {
        @include header-scales-bg
      }
    }

    &.gantt_grid_head_add {
      &.add-hidden {
        background-image: none !important;
        pointer-events: none;
      }
    }
  }

  .gantt_grid_data {
    .gantt_row {
      &:hover {
        @include empty-cell-container($row-selection-color);
      }

      &.gantt_selected {
        @include empty-cell-container($row-selection-color);
      }

      &.project {
        font-weight: bold;
      }

      &.expanded-project {
        border-bottom: 1px solid var(--wt-grey-dark);

        &:not(.gantt_selected) {
          &:not(:hover) {
            background-color: var(--wt-neutral);
          }

        }
      }

      &.sub-task {
        font-style: italic;

        .gantt_add {
          display: none !important;
        }
      }

      .gantt_cell {
        font-size: $gantt-font-size !important;

        &:has(.empty-cell-container) {
          padding-left: 0;
          padding-right: 0;
        }

        &.gantt_cell_tree {
          .gantt_tree_icon {
            &.grouped-tasks {
              font-size: 18px;
              margin: 6px 10px 0px 10px;
              width: 18px;
              height: 18px;
            }

            &.gantt_file {
              background-image: unset;

              &.new {
                @include gantt-file-icon("../resources/svgs/edit_pencil.svg");
              }
              &.project {
                @include gantt-file-icon("../resources/svgs/icon-project.svg");
              }
              &.task-group {
                @include gantt-file-icon("../resources/svgs/icon-task-group.svg");
              }
              &.task {
                @include gantt-file-icon("../resources/svgs/icon-task.svg");
              }
              &.sub-task {
                @include gantt-file-icon("../resources/svgs/icon-sub-task.svg");
              }
              &.milestone_task_silver {
                @include gantt-file-icon("../resources/svgs/milestone_task_silver.svg");
              }
              &.milestone_task_gold {
                @include gantt-file-icon("../resources/svgs/milestone_task_gold.svg");
              }
              &.milestone_task_bronze {
                @include gantt-file-icon("../resources/svgs/milestone_task_bronze.svg");
              }
              &.milestone_sub_task_silver {
                @include gantt-file-icon("../resources/svgs/milestone_sub_task_silver.svg");
              }
              &.milestone_sub_task_gold {
                @include gantt-file-icon("../resources/svgs/milestone_sub_task_gold.svg");
              }
              &.milestone_sub_task_bronze {
                @include gantt-file-icon("../resources/svgs/milestone_sub_task_bronze.svg");
              }
              &.account-logo-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 100%;
                max-width: 20px;
                max-height: 100%;
                min-width: 20px;
                min-height: 20px;
                margin-left: 6px;
                margin-right: 6px;

                .img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .gantt_tree_content {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .rag {
          display: inline-block;
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
        }

        i {
          display: inline-block;
          font-size: 14px;
          vertical-align: middle;

          &.headline-status {
            margin-top: -4px;
          }
        }

        //@include empty-cell-container(var(--wt-neutral-extra-light));
      }
    }

    .gantt_row div.cell-icon {
      height: 100%;
      display: inline-block;
    }

    .gantt_row div.cell-icon:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 100%;
    }
  }
}

.gantt_task {
  .gantt_task_scale {
    .gantt_scale_line {
      &.wet-scale-line {
        @include header-scales-bg;
        overflow: hidden !important;

        .gantt_scale_cell {
          font-size: $header-scales-font-size;
          color: var(--wt-grey-dark);

          &.weekend {
            @include weekend;
          }

          &.gantt_last_cell {
            border-right-width: 0px;
          }
        }
      }
    }
  }

  .gantt_data_area {
    .gantt_task_bg {
      .gantt_task_row {
        &.no-border {
          border: none;
        }

        &.expanded-project {
          &:not(.gantt_selected) {
            background-color: var(--wt-neutral);;
          }
        }

        &.gantt_selected {
          .gantt_task_cell {
            &.weekend {
              background: #f7eb91;
            }
          }
        }

        .gantt_task_cell {
          &.weekend {
            @include weekend;
          }
        }
      }
    }

    .gantt_bars_area {
      .gantt_task_line {
        border: 0 solid transparent;

        &.wetrack-project-task {
          &.rag-329F5B {
            @include gantt-project-line($rag-on-track-color, 8px, 18px);
          }
          &.rag-FD9C38 {
            @include gantt-project-line($rag-sleeping-color, 8px, 18px);
          }
          &.rag-939499 {
            @include gantt-project-line($rag-not-started-color, 8px, 18px);
          }
          &.rag-F41B1B {
            @include gantt-project-line($rag-slipped-color, 8px, 18px);
          }
          &.rag-0574DB {
            @include gantt-project-line($rag-complete-color, 8px, 18px);
          }

          .gantt_side_content {
            &.gantt_left {
              transform: translateY($gantt-default-task-left-side-adjust-y-offset) !important;
            }
          }
        }

        &.wetrack-task-group {
          &.rag-329F5B {
            @include gantt-project-line($rag-on-track-color, 6px, 14px, 0.0);
          }
          &.rag-FD9C38 {
            @include gantt-project-line($rag-sleeping-color, 6px, 14px, 0.0);
          }
          &.rag-939499 {
            @include gantt-project-line($rag-not-started-color, 6px, 14px, 0.0);
          }
          &.rag-F41B1B {
            @include gantt-project-line($rag-slipped-color, 6px, 14px, 0.0);
          }
          &.rag-0574DB {
            @include gantt-project-line($rag-complete-color, 6px, 14px, 0.0);
          }
        }

        &.wetrack-task {
          &.rag-329F5B {
            @include darken-task-border-color($rag-on-track-color, 10%);
          }
          &.rag-FD9C38 {
            @include darken-task-border-color($rag-sleeping-color, 10%);
          }
          &.rag-939499 {
            @include darken-task-border-color($rag-not-started-color, 10%);
          }
          &.rag-F41B1B {
            @include darken-task-border-color($rag-slipped-color, 10%);
          }
          &.rag-0574DB {
            @include darken-task-border-color($rag-complete-color, 10%);
          }
        }

        &.wetrack-subtask {
          @include gantt-subtask-line();

          .gantt_side_content {
            &.gantt_left {
              transform: translateY($gantt-sub-task-left-side-adjust-y-offset) !important;
            }
          }
        }

        &.wetrack-group-task {
          background-color: #d3d3d3 !important;
          border: 1px solid #d3d3d3 !important;
          opacity: 0.6 !important;

          &.gantt_selected {
            border: 1px solid #a3a3a3 !important;
            box-shadow: unset !important;
          }
        }

        &.wetrack-mileston-task {
          .gantt_task_content {
            animation: spinMilestone 3s linear infinite;
          }
        }

        &.gantt_milestone {
          .gantt_task_content {
            background: inherit;
            border: inherit;
            border-width: 2px;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            border-color: white;
            box-shadow: -2px 2px 7px 0px #999;
            width: 20px !important;
            height: 20px !important;
          }

          .gantt_side_content {
            &.gantt_left {
              transform: translateY($gantt-milestone-task-left-side-adjust-y-offset);
            }
          }

          .gantt_link_control {
            &.task_left {
              margin-left: 0 !important;
            }
            &.task_right {
              margin-right: 0 !important;
            }
          }
        }

        &.wetrack-account-task {
          background-color: white;
          border-color: white;

          .gantt_task_content {
            display: flex;
            gap: 2px;
          }
        }

        &.critical-task {
            border-color: #000000 !important;
        }

        &.transparent-task {
            opacity: 0.3;
        }

        // &.gantt_task_inline_color {
        //   border-color: unset;
        // }

        &.wetrack-runsheet {
          background-color: unset;
          border: 2px solid var(--wt-dark-sky-blue);
          border-left: 0px;
          border-right: 0px;
          border-bottom: 0px;
          border-radius: unset;
          margin-top: -4px;

          .gantt_task_content {
            text-align: left;
            color: #6e6e6e;
          }
        }

        .gantt_side_content {
          &.gantt_left {
            display: flex;
            align-items: center;
            height: 30px;
            gap: 8px;
            transform: translateY($gantt-default-task-left-side-adjust-y-offset);
          }

          &.gantt_right {
            .gantt_right-wrapper {
              display: flex;
              align-items: center;

              .gantt-priority-starts-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                margin: 0 5px;
              }

              .overdue {
                color: $rag-slipped-color;
              }

              .days-overdue {
                font-size: 10px;
                font-weight: bold;
              }
            }
          }

          &.gantt_link_crossing {
            bottom: 0;
            top: 0;
          }

          .grid-cell-owners-container {
            padding: 2px;
            height: fit-content;

            .owner-avatar {
              width: 22px;
              height: 22px;
            }
          }

          .timeline-headstatus-container {
            i {
              display: flex;
              align-items: center;
              height: 20px;
              font-size: 16px !important;
            }
          }
        }
      }
    }

    .gantt_marker_area {
      .timeline-restriction-marker {
        background-color: rgb(0,0,0,0.05);

        .gantt_marker_content {
          color: black;
        }

        &.left-restriction-marked {
          border-right: 1px solid #b6b6b6;
        }
        &.right-restriction-marked {
          border-left: 1px solid #b9b6b6;
        }
      }
    }

    .baseline {
      display: flex;
      align-items: center;
      position: absolute;
      border-radius: 0px;
      margin-top: 4px;
      height: 3px;
      z-index: 1;

      .task {
        height: 3px;
        float: left;
      }

      .milestone {
        width: 8px !important;
        height: 8px !important;
        float: left;
        transform: translate(-4px, 0px) rotate(45deg);

        & + {
          .label {
            left: 15px !important;
          }
        }
      }

      .label {
        .days {
          font-weight: bold
        }
      }
    }

    .dependency-icon {
      position: absolute;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      width: 22px;
      height: 22px;
      margin-top: 4px;
      z-index: 1;
      background-image: url("../resources/svgs/round-insert_link.svg");
      background-position: center;
      background-color: white;
      border-radius: 50%;
      background-repeat: no-repeat;

      .dependency-icon-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        background-color: #ff9b25;
        border-radius: 50%;
        position: absolute;
        right: -11px;
        top: -4px;
        color: white;
        font-size: 8px;
        font-weight: bold;
        box-sizing: border-box;
        border: 2px solid white;
      }
    }
  }
}


.empty-cell-container {
  width: 100%;
  height: 100%;

  .stripes-bg {
    width: 100%;
    height: 100%;
    padding-left: 6px;
    font-weight: normal;
    color: var(--wt-black);
    @include empty-cell-stripes(var(--wt-neutral-extra-light));
  }
}

.grid-cell-owners-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  z-index: 2;

  .owner-avatar {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -8px;
    background-color: var(--wt-blue-extra-pale);
    color: var(--wt-primary);
    font-size: 8px;
    font-weight: bold;
    background-size: 100%;
    border-radius: 50%;
    flex-shrink: 0;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25), -1px -1px 1px 0 rgba(0, 0, 0, 0.04);
    -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25), -1px -1px 1px 0 rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25), -1px -1px 1px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid white;

    &:hover {
      transform: scale(1.2);
      transition: .5s;
    }

    &:last-child {
      margin-right: unset !important;
    }

    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
    }
  }

  .owner-name {
    margin-left: $gantt-font-size;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
  }
}

.gantt-priority-starts-container {
  i {
    padding: 0 !important;

    &.material-icons-outlined {
      &.priority_star {
        color: var(--wt-grey-light);

        &.tiny {
          font-size: 12px;
        }
        &.large {
          font-size: 14px;
        }
      }

      &.checked {
        color: var(--wt-black);
      }

      &.hovered {
        color: var(--wt-grey-dark);
      }
    }
  }
}

.headline-status {
  &:hover {
    cursor: pointer;
    color: var(--wt-primary) !important;
    transition: 0.3s;
  }

  &.headline-status-empty {
    color: var(--wt-grey-mid);
  }
  &.headline-status-active {
    color: var(--wt-black);
  }
}

.tml-rest-overlay-btn {
  position: absolute;
  bottom: 35px;
  left: 50%;
  padding: 4px 8px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.5s, opacity 0.5s;
  background-color: white;
  border-radius: 4px;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.24), -1px -1px 1px 0 rgba(0, 0, 0, 0.08);
  border: 2px solid red;

  &:hover {
    background: rgba(245, 177, 177, 0.5);
    opacity: 1;
  }
}

.task-group-separator {
  border-bottom: 2px solid var(--wt-grey-mid);
}


.wetrack-runsheet-item.no-drag-right .gantt_task_drag.task_right {
  display: none !important;
}

.wetrack-runsheet-item.color-default {
  background-color: var(--wt-light-sky-blue);
}

.wetrack-runsheet-item.color-gradient {
  background: var(--wt-light-sky-blue);
  background: linear-gradient(90deg, var(--wt-light-sky-blue) 0%,  #3db9d300 100%);
  border-right: 0;
  border-top: 0;
  border-bottom: 0;
}

.runsheet-item-right-side-text {
  display: flex;
  align-items: center;
}

.runsheet-item-right-side-text .bold-text {
  font-weight: bold;
}

.runsheet-item-priority-wrapper {
  height: 17px;
  padding-left: 5px;
}

.runsheet-item-priority-star {
  font-size: 12px;
  color: #6e6e6e;
}

.runsheet-item-priority-star.light-star {
  color: var(--wt-grey-light) !important;
}

//End runsheets



@-moz-keyframes spinMilestone {
    0% { -moz-transform: rotateY(0deg) rotate(-45deg); }
    100% { -moz-transform: rotateY(360deg) rotate(-45deg)}
}

@-webkit-keyframes spinMilestone {
    0% { -webkit-transform: rotateY(0deg) rotate(-45deg);}
    100% { -webkit-transform: rotateY(360deg) rotate(-45deg);}
}

@keyframes spinMilestone {
    0% {  transform: rotateY(0deg) rotate(-45deg); }
    100% { transform: rotateY(360deg) rotate(-45deg) }
}


.gantt_workload_expand_button {
    position: absolute;
    top: 50%;
    bottom: 50%;
    width: 18px;
    height: 32px;
    position: absolute;
    background-color: #3a424a;
    text-align: center;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    z-index: 2;
    opacity: 1;
    border: none;
}

.gantt_workload_expand_button, .gantt_workload_expand_button:hover {
    transition: right .3s ease-in-out,opacity .3s ease-in-out,background-color .2s ease-in-out;
}

.gantt_workload_expand_button {
    border-bottom: none;
    display: flex;
    margin-top: -18px;
}

.gantt_workload_expand_button:hover {
    background-color: #39c;
    opacity: 1 !important;
    pointer-events: all;
}

.gantt_workload_expand_button:after, .gantt_workload_expand_button:before {
  background: transparent;
  content: "";
  position: absolute;
  pointer-events: none;
  transition: opacity .3s ease-in-out,background-color .2s ease-in-out;
}

.gantt_workload_expand_button:hover:after, .gantt_workload_expand_button:hover:before {
  background-color: #39c;
  opacity: 1 !important;
  pointer-events: none;
  transition: opacity .3s ease-in-out,background-color .2s ease-in-out;
  z-index: 2;
}

.gantt_workload_expand_button:after, .gantt_workload_expand_button:before {
    width: 5000px;
    height: 1px;
    top: 17px;
}

.gantt_workload_expand_button:before {
    left: 0;
}

.gantt_workload_expand_button:after {
    right: 0;
}

.gantt_resizer_x:hover ~ .gantt_expand_button, .gantt_resizer_y:hover ~ .gantt_workload_expand_button, .grid_cell:hover ~ .gantt_expand_button, .scrollHor_cell:hover ~ .gantt_workload_expand_button, .workload-body:hover ~ .gantt_workload_expand_button, .workload-header:hover ~ .gantt_workload_expand_button {
    background-color: #828282;
    pointer-events: all;
    transition: opacity .3s ease-in-out,background-color .2s ease-in-out;
}

.gantt_resizer_x:hover ~ .gantt_expand_button:after, .gantt_resizer_x:hover ~ .gantt_expand_button:before, .gantt_resizer_y:hover ~ .gantt_workload_expand_button:after, .gantt_resizer_y:hover ~ .gantt_workload_expand_button:before, .grid_cell:hover ~ .gantt_expand_button:after, .grid_cell:hover ~ .gantt_expand_button:before, .scrollHor_cell:hover ~ .gantt_workload_expand_button:after, .scrollHor_cell:hover ~ .gantt_workload_expand_button:before, .workload-body:hover ~ .gantt_workload_expand_button:after, .workload-body:hover ~ .gantt_workload_expand_button:before, .workload-header:hover ~ .gantt_workload_expand_button:after, .workload-header:hover ~ .gantt_workload_expand_button:before {
    background-color: #828282;
    opacity: 1 !important;
    pointer-events: none;
    transition: opacity .3s ease-in-out,background-color .2s ease-in-out;
}

.gantt_workload_expand_button_top ~ .gantt_workload_expand_button_text {
    display: none;
}

.gantt_workload_expand_button_top {
    background-position: 50% 50%;
    background-position: 50%;
    transform: rotate(-90deg);
}

.gantt_workload_expand_button_bottom, .gantt_workload_expand_button_top {
    background-image: url("../resources/svgs/resizer-arrow.svg");
    background-repeat: no-repeat;
    height: inherit;
    width: 30px;
    border: none;
    transition: opacity .3s ease-in-out,background-color .2s ease-in-out;
}

.gantt_workload_expand_button_bottom {
    background-position: 50% 50%;
    transform: rotate(90deg);
}

.gantt_workload_expand_button_text {
    font-family: Lato;
    font-size: 12px;
    line-height: 18px;
    height: inherit;
    color: #fff;
    position: absolute;
    left: 30px;
    top: 0;
    border: none;
}


.gantt-tooltip-rags-container {
  padding: 0px;

  .gantt-tooltip-rag-row {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1px 0;

    .gantt-tooltip-rag-color {
      width: 12px;
      height: 12px;
      border-radius: 25%;
    }

    .gantt-tooltip-rag-count {
      flex:1;
      padding-left: 8px;
      font-size: 11px;
    }
  }
}

.gantt_grid_editor_placeholder .wt-text-inline-editor-wrapper {
  margin: 0 1px;
}

.gantt_grid_editor_placeholder .wt-text-inline-editor-wrapper .wt-text-inline-editor{
  background-color:white;
  border: 0;
  box-shadow: 0 0 0 1px var(--wt-primary);
  padding: 0 5px;
}


//Export related styles
.export-doc-footer-container {
  width: 100%;
  padding: 20px 0 20px 20px;

  .content-wrapper {
    max-width: 150px;

    .wetrack-logo {
      max-width: 150px;
      max-height: 32px;

      img {
        max-width: 150px;
        max-height: 32px;
      }
    }

    .export-date {
      width: 150px;
      text-align: left;
      font-weight: bold;
      font-size: 18px;
      color: #555;
      margin-top: 13px;
    }
  }
}
