@mixin lozenge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  padding: 0 8px;
}

@mixin lozenge-small {
  @include lozenge();

  height: 16px;
  font-size: 10px;
  border-radius: 8px;
}

@mixin lozenge-large {
  @include lozenge();

  height: 26px;
  font-size: 14px;
  border-radius: 14px;
}

.live-lozenge {
  @include lozenge-large();

  background-color: var(--wt-red);
}

.live-lozenge-small {
  @include lozenge-small();

  background-color: var(--wt-red);
}

.upcoming-lozenge {
  @include lozenge-large();

  background-color: var(--wt-grey-mid);
}

.upcoming-lozenge-small {
  @include lozenge-small();

  background-color: var(--wt-grey-mid);
}

.active-lozenge {
  @include lozenge-large();

  background-color: var(--wt-green);
}

.active-lozenge-small {
  @include lozenge-small();

  background-color: var(--wt-green);
}

.complete-lozenge {
  @include lozenge-large();

  background-color: var(--wt-primary);
}

.complete-lozenge-small {
  @include lozenge-small();

  background-color: var(--wt-primary);
}
