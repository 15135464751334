.list-view-header-layout {
  display: flex;
  height: 32px;
  border-bottom: 1px solid #e0e0e0;
  margin: 10px 14px 0 14px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  > *:not(:last-child) {
    margin-right: 8px;
  }

  .list-view-header-layout-title {
    font-size: 20px;
    flex: 1;
  }

  .list-view-header-layout-title-description {
    font-size: 14px;
    color: #939899;
    margin-left: 8px;
  }

  .list-view-header-layout-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  &.list-view-header-layout-no-title-description {
    height:44px;
    .list-view-header-layout-title,
    .list-view-header-layout-title-description {
      display: none;
    }
  }
}

@media (max-width: 576px) {
  .list-view-header-layout {
    display: block !important;
    padding: 8px 0;

    &:not(.list-view-header-layout-no-title-description) {
      .list-view-header-layout-title {
        margin-bottom: 8px;
      }
    }

    .list-view-header-layout-buttons {
      app-list-search-input {
        flex: 1;

        .search-icon {
          justify-content: flex-end;
        }

        .list-search-input-wrapper {
          width: 100%;

          .list-search-input-inner-wrapper {
            width: 100%;

            input {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
