.runsheet-items-clustericon {
    background-color: var(--wt-primary);
    color: #fff;
    border-radius: 100%;
    font-family: ProximaNova;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .runsheet-items-clustericon::before,
  .runsheet-items-clustericon::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background-color: var(--wt-primary);
    opacity: 0.2;
    border-radius: 100%;
  }

  .runsheet-items-clustericon::before {
    padding: 7px;
  }

  .runsheet-items-clustericon::after {
    padding: 14px;
  }

  .runsheet-items-clustericon-1 {
    background-color: var(--wt-primary);
  }

  .runsheet-items-clustericon-2 {
    background-color: var(--wt-primary);
  }

  .runsheet-items-clustericon-3 {
    background-color: var(--wt-primary);
  }
