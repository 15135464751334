:root {
  --wt-silver-light: #f4f2f0;
  --wt-silver: #c7c0b3;
  --wt-bronze-light: #f2ebe7;
  --wt-bronze: #bf9986;
  --wt-gold-light: #f7f1e4;
  --wt-gold: #d9b877;
  --wt-grey: #cfcdc9;
  --wt-red: #c54644;
  --wt-orange: #f87c56;
  --wt-light-orange: #f8ad6d;
  --wt-yellow: #eed484;
  --wt-green: #56c271;
  --wt-blue-dark: #0086d6;
  --wt-blue-light: #4ec3e0;
  --wt-black: #353f47;
  --wt-grey-dark: #939699;
  --wt-grey-mid: #cccccc;
  --wt-grey-light: #e0e0e0;
  --wt-grey-extra-light: #f9f9fb;
  --wt-blue-pale: #f0f3f5;
  --wt-blue-extra-pale: #f7f9fa;
  --off-white: #fcfcfc;
  --white: #ffffff;
  --wt-box-shadow: 1px 4px 16px 0 rgba(0, 0, 0, 0.08), 1px 2px 4px 0 rgba(0, 0, 0, 0.08);

  --wt-dark-sky-blue: #4ec3e0;
  --wt-light-sky-blue: #ededfc;

  // This could be changed with JavaScript to font-size:16px when the device is iOS , to prevent the auto-zoom
  --input-field-font-size: 14px;

  --custom-background-color: #F8F7FA;

  //Momentus colors
  --mom-dark-blue: #14164f;
  --mom-yellow: #fff35f;
  --mom-white: #ffffff;
  --mom-danger: #dc3545;
  --mom-neutral: #FCFBFA;
  --mom-primary: #4146e0;
  --mom-grey: #B0B1C8;

  // New RAG colours
  --rag-red: #F41B1B;
  --rag-amber: #FD9C38;
  --rag-green: #329F5B;
  --rag-grey: #939499;
  --rag-blue: #0574DB;

  // Greyscale
  --wt-greyscale-2: var(--wt-grey-extra-light);
  --wt-greyscale-4: #DDDDE1;
  --wt-greyscale-3: #F0F0F5;
  --wt-greyscale-6: #939499;

  // --mom-dark-blue: #1c1e61;
  // --mom-yellow: #fff35f;
  // --mom-white: #ffffff;



  // --------------------------- Theming with old WT colors ---------------------------
  // --wt-primary: var(--wt-blue-light);
  // --wt-secondary: var(--wt-blue-extra-pale);
  // --wt-danger: var(--wt-red);
  // --wt-neutral: var(--wt-blue-pale);
  // --wt-neutral-light: var(--wt-blue-extra-pale);
  // --wt-neutral-extra-light: #f2f4f5;

  // // ---- WT Hover buttons colors -----
  // --wt-primary-hover: #46b2cc;
  // --wt-secondary-hover: var(--wt-blue-pale);
  // --wt-danger-hover: #b03f3d;

  // //Sidebar
  // --wt-sidebar-background: var(--wt-black);
  // --wt-sidebar-text: var(--wt-grey-dark);
  // --wt-sidebar-text-hover: var(--white);
  // --wt-sidebar-text-active: var(--white);

  //Lists
  // --wt-list-background: var(--wt-white);



  // --------------------------- Theming with new WT Momentus colors ---------------------------
  --wt-primary: var(--mom-primary);
  --wt-secondary: var(--mom-dark-blue);
  --wt-danger: var(--mom-danger);
  --wt-neutral: var(--mom-neutral);
  --wt-neutral-light: var(--wt-blue-extra-pale);
  --wt-neutral-extra-light: var(--mom-neutral);
  // ---- Momentus Hover buttons colors -----
  --wt-primary-hover: #2f24e3;
  --wt-secondary-hover: #14164f;
  --wt-danger-hover: #cf2e2e;

  //Sidebar
  --wt-sidebar-background: linear-gradient(180deg, #1C1E61 0%, #1C1E61 61.93%, #000 100%);;
  --wt-sidebar-text: var(--mom-grey);
  --wt-sidebar-text-hover: var(--mom-white);
  --wt-sidebar-text-active: var(--mom-white);
}
