// Change bootstrap default gutters;
// Add it next to .row class e.g. class="row custom-gutter"
.custom-gutters {
    margin-right: -8px;
    margin-left: -8px;
  
    > [class*="col-"] {
      padding-right: 8px;
      padding-left: 8px;
    }
}