.details-layout {
  .details-layout-heading {
    color: #939899;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 2px;
  }

  .details-layout-description {
    color: #424647;
    font-size: 16px;
    line-height: 20px;

    &.large-details-layout-description {
      font-size: 18px;
      line-height: 20px;
    }

    &.overflow-hidden {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .details-layout-border-bottom {
    border-bottom: 1px solid #ebebeb;
  }
}

.details-archive-banner {
  background-color: #fdfae5;
  background-size: 14px 14px;
  min-height: 30px;
  padding: 12px 12px 12px 50px;
  position: relative;
}
.details-archive-banner-text {
  font-size: 16px;
  line-height: 30px;
  margin: 0;
}

.details-archive-banner-icon {
  position: absolute;
  top: 12px;
  left: 12px;
}