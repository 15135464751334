/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

ion-content {
  --background: unset;
}


ion-backdrop {
  background-color: black;

}
